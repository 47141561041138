import styled from "@emotion/styled"

export const SheetMusicView = styled.div`
  background-color: lightgray;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`
